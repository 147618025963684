import { FontFamilyProvider } from "@react-three/uikit";
import { ReactNode } from "react";
import { Color } from "three";

/** Helper function to create a color from HSL values.
 * @param h The hue value.
 * @param s The saturation value.
 * @param l The lightness value. */
function hsl(h: number, s: number, l: number) {
    return new Color().setHSL(h / 360, s / 100, l / 100, "srgb");
}

/** Helper function to create a color from a hextring.
 * @param hexString The hex string to convert to a color. */
function colorHex(hexString: string) {
    return new Color().setHex(parseInt(hexString.slice(1), 16), "srgb");
}

/**  All the base colors in tailwind style shades.
 * Instead of using these colors, prefer using the theme variables defined below. */
export const baseColors = {
    titan: {
        50: hsl(30, 14, 97),
        100: hsl(15, 13, 94),
        200: hsl(17, 16, 92),
        300: hsl(16, 16, 82),
        400: hsl(18, 15, 71),
        500: hsl(17, 14, 60),
        600: hsl(18, 12, 51),
        700: hsl(18, 13, 42),
        800: hsl(16, 12, 35),
        900: hsl(19, 10, 31),
        950: hsl(20, 11, 15),
    },
    alabaster: {
        50: hsl(0, 0, 98),
        100: hsl(0, 0, 94),
        200: hsl(0, 0, 86),
        300: hsl(0, 0, 74),
        400: hsl(0, 0, 60),
        500: hsl(0, 0, 49),
        600: hsl(0, 0, 40),
        700: hsl(0, 0, 32),
        800: hsl(0, 0, 27),
        900: hsl(0, 0, 24),
        950: hsl(0, 0, 16),
    },
    swirl: {
        50: hsl(0, 0, 97),
        100: hsl(0, 7, 94),
        200: hsl(15, 7, 89),
        300: hsl(10, 7, 82),
        400: hsl(7, 6, 71),
        500: hsl(9, 6, 60),
        600: hsl(10, 5, 51),
        700: hsl(11, 5, 42),
        800: hsl(8, 4, 35),
        900: hsl(10, 4, 31),
        950: hsl(12, 6, 15),
    },
    gold: {
        50: hsl(54, 100, 96),
        100: hsl(51, 100, 89),
        200: hsl(52, 100, 76),
        300: hsl(49, 100, 64),
        400: hsl(47, 100, 55),
        500: hsl(40, 100, 50),
        600: hsl(34, 100, 44),
        700: hsl(28, 98, 37),
        800: hsl(25, 90, 31),
        900: hsl(24, 84, 26),
        950: hsl(23, 100, 14),
    },
} as const;

/**  All the base colors in tailwind style shades.
 * Instead of using these colors, prefer using the theme variables defined below. */
export const alertColors = {
    red: {
        50: colorHex("#fef2f2"),
        100: colorHex("#fee2e2"),
        200: colorHex("#fecaca"),
        300: colorHex("#fca5a5"),
        400: colorHex("#f87171"),
        500: colorHex("#ef4444"),
        600: colorHex("#dc2626"),
        700: colorHex("#b91c1c"),
        800: colorHex("#991b1b"),
        900: colorHex("#7f1d1d"),
        950: colorHex("#450a0a"),
    },
    blue: {
        50: colorHex("#eff6ff"),
        100: colorHex("#dbeafe"),
        200: colorHex("#bfdbfe"),
        300: colorHex("#93c5fd"),
        400: colorHex("#60a5fa"),
        500: colorHex("#3b82f6"),
        600: colorHex("#2563eb"),
        700: colorHex("#1d4ed8"),
        800: colorHex("#1e40af"),
        900: colorHex("#1e3a8a"),
        950: colorHex("#172554"),
    },
    orange: {
        50: colorHex("#fff7ed"),
        100: colorHex("#ffedd5"),
        200: colorHex("#fed7aa"),
        300: colorHex("#fdba74"),
        400: colorHex("#fb923c"),
        500: colorHex("#f97316"),
        600: colorHex("#ea580c"),
        700: colorHex("#c2410c"),
        800: colorHex("#9a3412"),
        900: colorHex("#7c2d12"),
        950: colorHex("#431407"),
    },
    green: {
        50: colorHex("#f0fdf4"),
        100: colorHex("#dcfce7"),
        200: colorHex("#bbf7d0"),
        300: colorHex("#86efac"),
        400: colorHex("#4ade80"),
        500: colorHex("#22c55e"),
        600: colorHex("#16a34a"),
        700: colorHex("#15803d"),
        800: colorHex("#166534"),
        900: colorHex("#14532d"),
        950: colorHex("#052e16"),
    },
} as const;

/** For consistent theming across the application, use these colors. */
export const colors = {
    background: baseColors.titan[100],
    surface: baseColors.swirl[200],
    muted: baseColors.alabaster[200],
    text: baseColors.alabaster[950],
    textSecondary: baseColors.alabaster[700],
    textMuted: baseColors.alabaster[500],
    textDisabled: baseColors.alabaster[400],
    accent: baseColors.gold[500],
    highlight: baseColors.gold[600],
    border: baseColors.swirl[800],
    primary: baseColors.titan[300],
    primaryHover: baseColors.titan[400],
    primaryActive: baseColors.titan[500],
    primaryDisabled: baseColors.swirl[200],
    error: alertColors.red[400],
    warning: alertColors.orange[400],
    success: alertColors.green[400],
} as const;

/** Typography settings for the application. */
export const typography = {
    heading: {
        1: { fontSize: 48, lineHeight: 56 },
        2: { fontSize: 36, lineHeight: 44 },
        3: { fontSize: 28, lineHeight: 36 },
        4: { fontSize: 24, lineHeight: 32 },
        5: { fontSize: 20, lineHeight: 28 },
        6: { fontSize: 18, lineHeight: 24 },
    },
    subheading: {
        1: { fontSize: 24, lineHeight: 32 },
        2: { fontSize: 22, lineHeight: 30 },
        3: { fontSize: 20, lineHeight: 28 },
        4: { fontSize: 18, lineHeight: 26 },
        5: { fontSize: 16, lineHeight: 24 },
        6: { fontSize: 14, lineHeight: 22 },
    },
    text: { fontSize: 14, lineHeight: 21 },
} as const;
// TODO: Add lineHeight to the sizes.
export const sizes = {
    xs: {
        iconSize: 12,
        height: 24,
        padding: 6,
        borderRadius: 6,
        fontSize: 8,
    },
    sm: {
        height: 32,
        padding: 12,
        borderRadius: 8,
        fontSize: 12,
        iconSize: 14,
    },
    md: {
        height: 44,
        padding: 20,
        borderRadius: 10,
        fontSize: 14,
        iconSize: 18,
    },
    lg: {
        height: 52,
        padding: 25,
        borderRadius: 12,
        fontSize: 16,
        iconSize: 22,
    },
    xl: {
        height: 56,
        padding: 29,
        borderRadius: 14,
        fontSize: 18,
        iconSize: 28,
    },
} as const;

export type Size = keyof typeof sizes;

/**
 * Provides our application fonts to the children, which can access them
 * using the `fontFamily` property.
 * @param children the children to proivde the fonts to.
 */
export function DefaultFonts({ children }: { children: ReactNode }) {
    return (
        <FontFamilyProvider
            poppins={{
                "semi-bold": "/assets/fonts/uikit/Poppins-SemiBold.json",
                bold: "/assets/fonts/uikit/Poppins-Bold.json",
                "extra-bold": "/assets/fonts/uikit/Poppins-ExtraBold.json",
                light: "/assets/fonts/uikit/Poppins-Light.json",
                "extra-light": "/assets/fonts/uikit/Poppins-ExtraLight.json",
                thin: "/assets/fonts/uikit/Poppins-Thin.json",
                normal: "/assets/fonts/uikit/Poppins-Regular.json",
                medium: "/assets/fonts/uikit/Poppins-Medium.json",
                black: "/assets/fonts/uikit/Poppins-Black.json",
            }}
            open-sans={{
                "semi-bold": "/assets/fonts/uikit/OpenSans-SemiBold.json",
                bold: "/assets/fonts/uikit/OpenSans-Bold.json",
                "extra-bold": "/assets/fonts/uikit/OpenSans-ExtraBold.json",
                light: "/assets/fonts/uikit/OpenSans-Light.json",
                medium: "/assets/fonts/uikit/OpenSans-Medium.json",
                normal: "/assets/fonts/uikit/OpenSans-Regular.json",
            }}
        >
            {children}
        </FontFamilyProvider>
    );
}
