import "/resources/css/app.css";

import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import React from "react";
import { createRoot } from "react-dom/client";
import { Object3D } from "three";

import { RouteContext } from "@/hooks/useRoute";

import { colors } from "./components/xr/Theme";

Object3D.DEFAULT_UP.set(0, 0, 1);

createInertiaApp({
    title: (title) => `${title} - Evolve Robotics`,
    progress: { color: colors.accent.getHex().toString() },
    resolve: (name) =>
        resolvePageComponent(
            `./scenes/${name}.tsx`,
            import.meta.glob("./scenes/**/*.tsx"),
        ),
    setup({ el, App, props }) {
        return createRoot(el).render(
            <React.StrictMode>
                <RouteContext.Provider value={(window as any).route}>
                    <App {...props} />
                </RouteContext.Provider>
            </React.StrictMode>,
        );
    },
});
